var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { businessService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject, Debounce } from "~/core/decorator";
var HistoryCasesService = /** @class */ (function () {
    function HistoryCasesService() {
    }
    /**
   * 查询退案案件
   */
    HistoryCasesService.prototype.queryReturnCases = function (data, page, sort, loading) {
        return this.netService.send({
            server: businessService.historyCasesController.queryReturnCases,
            data: {
                principalId: data.principalId,
                batchNumber: data.batchNumber,
                personalName: data.personalName,
                certificateNo: data.certificateNo,
                caseNumber: data.caseNumber,
                cardNo: data.cardNo,
                phone: data.phone,
                caseType: data.caseType,
                stage: data.stage,
                retireCaseDays: data.retireCaseDays,
                employerName: data.employerName,
                address: data.address,
                overdueAmtTotalStart: data.overdueAmtTotal ? data.overdueAmtTotal.min : null,
                overdueAmtTotalEnd: data.overdueAmtTotal ? data.overdueAmtTotal.max : null,
                leftAmtStart: data.leftAmt ? data.leftAmt.min : null,
                leftAmtEnd: data.leftAmt ? data.leftAmt.max : null,
                payStatus: data.payStatus,
                delegationDateStart: data.delegationDate ? data.delegationDate[0] : null,
                delegationDateEnd: data.delegationDate ? data.delegationDate[1] : null,
                overduePeriodsStart: data.overduePeriods ? data.overduePeriods.min : null,
                overduePeriodsEnd: data.overduePeriods ? data.overduePeriods.max : null,
                overdueDaysStart: data.overdueDays ? data.overdueDays.min : null,
                overdueDaysEnd: data.overdueDays ? data.overdueDays.max : null,
                city: data.city,
                endCaseDateStart: data.endCaseDate ? data.endCaseDate[0] : null,
                endCaseDateEnd: data.endCaseDate ? data.endCaseDate[1] : null,
                caseNumbers: data.caseNumbers ? data.caseNumbers.split(",") : null,
                certificateNos: data.certificateNos ? data.certificateNos.split(",") : null,
                phones: data.phones ? data.phones.split(",") : null,
            },
            page: page,
            sort: sort,
            loading: loading
        });
    };
    /**
     * 删除案件查询
     */
    HistoryCasesService.prototype.queryDeleteCases = function (data, page, sort, loading) {
        return this.netService.send({
            server: businessService.historyCasesController.queryDeleteCases,
            data: {
                principalId: data.principalId,
                batchNumber: data.batchNumber,
                personalName: data.personalName,
                certificateNo: data.certificateNo,
                caseNumber: data.caseNumber,
                cardNo: data.cardNo,
                caseType: data.caseType,
                phone: data.phone,
                stage: data.stage,
                retireCaseDays: data.retireCaseDays,
                employerName: data.employerName,
                address: data.address,
                overdueAmtTotalStart: data.overdueAmtTotal ? data.overdueAmtTotal.min : null,
                overdueAmtTotalEnd: data.overdueAmtTotal ? data.overdueAmtTotal.max : null,
                leftAmtStart: data.leftAmt ? data.leftAmt.min : null,
                leftAmtEnd: data.leftAmt ? data.leftAmt.max : null,
                paymentStatus: data.paymentStatus,
                delegationDateStart: data.delegationDate ? data.delegationDate[0] : null,
                delegationDateEnd: data.delegationDate ? data.delegationDate[1] : null,
                overduePeriodsStart: data.overduePeriods ? data.overduePeriods.min : null,
                overduePeriodsEnd: data.overduePeriods ? data.overduePeriods.max : null,
                overdueDaysStart: data.overdueDays ? data.overdueDays.min : null,
                overdueDaysEnd: data.overdueDays ? data.overdueDays.max : null,
                city: data.city,
                endCaseDateStart: data.endCaseDate ? data.endCaseDate[0] : null,
                endCaseDateEnd: data.endCaseDate ? data.endCaseDate[1] : null,
                deleteCaseDateStart: data.deleteCaseDate ? data.deleteCaseDate[0] : null,
                deleteCaseDateEnd: data.deleteCaseDate ? data.deleteCaseDate[1] : null,
                caseNumbers: data.caseNumbers ? data.caseNumbers.split(",") : null,
                certificateNos: data.certificateNos ? data.certificateNos.split(",") : null,
                phones: data.phones ? data.phones.split(",") : null,
            },
            page: page,
            sort: sort,
            loading: loading
        });
    };
    /**
     * 退案案件删除
     */
    HistoryCasesService.prototype.deleteCases = function (personalIds, batchNumber) {
        return this.netService.send({
            server: businessService.historyCasesController.deleteCases,
            data: {
                personalIds: personalIds,
                batchNumber: batchNumber
            }
        });
    };
    /**
     * 退案案件查询批次号
     */
    HistoryCasesService.prototype.getReturnCasesBatchNumber = function () {
        return this.netService.send({
            server: businessService.historyCasesController.getReturnCasesBatchNumber
        });
    };
    /**
    * 删除案件获取所有批次号
    */
    HistoryCasesService.prototype.getDeleteCasesBatchNumber = function () {
        return this.netService.send({
            server: businessService.historyCasesController.getDeleteCasesBatchNumber
        });
    };
    /**
     * 永久删除案件信息
     */
    HistoryCasesService.prototype.deleteCasesPermanent = function (personalIds, batchNumber) {
        return this.netService.send({
            server: businessService.historyCasesController.deleteCasesPermanent,
            data: {
                personalIds: personalIds,
                batchNumber: batchNumber
            }
        });
    };
    /**
     * 添加工单
     */
    HistoryCasesService.prototype.addHistoryCaseWorkerOrder = function (data) {
        return this.netService.send({
            server: businessService.historyCasesController.addHistoryCaseWorkerOrder,
            data: data
        });
    };
    /**
     * 退案恢复
     */
    HistoryCasesService.prototype.recoverCaseToNormal = function (ids, model) {
        return this.netService.send({
            server: businessService.historyCasesController.recoverCaseToNormal,
            data: {
                ids: ids,
                endCaseDate: model.endCaseDate
            }
        });
    };
    __decorate([
        Inject(NetService)
    ], HistoryCasesService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], HistoryCasesService.prototype, "queryReturnCases", null);
    __decorate([
        Debounce()
    ], HistoryCasesService.prototype, "queryDeleteCases", null);
    return HistoryCasesService;
}());
export { HistoryCasesService };
